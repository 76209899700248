<template>
  <div>
    <el-card shadow="never">
      <el-form :inline="true" :model="listQuery" ref="listQuery">
        <el-form-item label="" prop="categoryId">
          <cate-select  @categoryChange="categoryChange" ref="categorySelect" v-model="listQuery.categoryId" :set-default="false" :clearable=true></cate-select>
        </el-form-item>
        <el-form-item label="" prop="shopId">
          <shop-select  @shopChange="shopChange" ref="shopSelect" v-model="listQuery.shopId" :set-default="false" :clearable=true></shop-select>
        </el-form-item>
        <el-form-item label="商品名称" prop="name">
          <el-input  v-model="listQuery.name" placeholder="请输入商品名称"></el-input>
        </el-form-item>
        <el-form-item label="显示位置" prop="location">
          <el-select v-model="listQuery.location" style="width:100%" placeholder="请选择展示位置">
            <el-option
              v-for="item in locations"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="供应商" prop="supplierId">
          <el-select v-model="listQuery.supplierId" style="width:100%" placeholder="请选择供应商">
            <el-option
              v-for="item in suppliers"
              :key="item.supplierId"
              :label="item.name"
              :value="item.supplierId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="newListGoods">查询</el-button>
          <el-button @click="resetForm()">重置</el-button>
          <el-button type="primary" @click="batchUpdateGoodsShop">批量更改店铺</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-card style="margin-top: 10px">
      <!--<el-button style="margin-bottom: 10px;" type="primary" size="mini" icon="el-icon-plus" :disabled="isBtnDisabled" @click="addDialogVisible = true">
        添加商品
      </el-button>-->
      <el-table @sort-change="changeSort" :data="goodsList" border stripe @selection-change="handleSelectionChange">
        <!-- 基本信息 -->
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" label="#" align="center"></el-table-column>
        <el-table-column prop="name" label="商品名称"></el-table-column>
        <el-table-column prop="logo" label="商品图标">
          <template slot-scope="scope">
            <el-image
              style="height: 50px; width: 50px"
              :src="scope.row.imageUrl"
            ></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="location" label="展示位置">
          <template slot-scope="scope">{{ scope.row.location | formatLocation }}</template>
        </el-table-column>
        <el-table-column prop="supplyPrice" label="成本价">
          <template slot-scope="scope">
            {{ scope.row.supplyPrice | formatFloat }}
          </template>
        </el-table-column>
        <el-table-column prop="marketPrice" label="市场建议价">
          <template slot-scope="scope">
            {{ scope.row.marketPrice | formatFloat }}
          </template>
        </el-table-column>
        <el-table-column prop="rate" label="利润率" sortable="custom">
          <template slot-scope="scope">
            {{ scope.row.rate }}
          </template>
        </el-table-column>
        <el-table-column prop="salesRate" label="实际利润率" sortable="custom">
        </el-table-column>
        <el-table-column prop="price" label="销售价">
          <template slot-scope="scope">
            {{ scope.row.price | formatFloat }}
          </template>
        </el-table-column>
        <!--<el-table-column prop="shopName" label="店铺"></el-table-column>-->
        <el-table-column prop="newBuyerPrice" label="新人价"></el-table-column>
        <el-table-column prop="status" width="200" label="状态">
          <template slot-scope="scope">
            <p>上架状态：
              <el-switch v-model="scope.row.status" :active-value=1
                       :inactive-value=0 @change="changeGoodsStatus(scope.row)">
              </el-switch>
            </p>
            <p>是否夺宝：
              <el-switch v-model="scope.row.isLottery" :active-value=1
                         :inactive-value=0 @change="changeGoodsLottery(scope.row)">
              </el-switch>
            </p>
            <p>推广状态：
              <el-switch v-model="scope.row.adStatus" :active-value=1
                         :inactive-value=0 @change="changeGoodsAdStatus(scope.row)">
              </el-switch>
            </p>
            <p v-if="scope.row.supplierId!==0">供应商上架状态：
              <el-switch :disabled=true v-model="scope.row.supplyStatus" :active-value=2
                         :inactive-value=1>
              </el-switch>
            </p>
            <p v-if="scope.row.supplierId!==0">规格已全下架：
              <el-switch :disabled=true v-model="scope.row.skuStatus" :active-value=1
                         :inactive-value=0>
              </el-switch>
            </p>
          </template>
        </el-table-column>
        <!--<el-table-column prop="status" width="150" label="商品状态">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.status" active-text="上架" inactive-text="下架" :active-value=1
                       :inactive-value=0 @change="changeGoodsStatus(scope.row)">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="isLottery" width="120" label="参与夺宝">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.isLottery" active-text="是" inactive-text="否" :active-value=1
                       :inactive-value=0 @change="changeGoodsLottery(scope.row)">
            </el-switch>
          </template>
        </el-table-column>-->
        <el-table-column label="操作" width="120px">
          <template slot-scope="scope">
            <el-row>
            <el-button style="width: 100px;margin: 5px" type="primary" icon="el-icon-edit" size="mini" @click="editGoods(scope.row)">编辑</el-button>
            </el-row>
            <el-row>
              <el-button style="width: 100px;margin: 5px"  type="primary" size="mini" icon="el-icon-copy-document" @click="showDialog(scope.row.goodsId, scope.row.shopId)">更改店铺</el-button>
            </el-row>
            <el-row>
              <el-button style="width: 100px;margin: 5px"  type="success" icon="el-icon-s-comment" size="mini" @click="commentGoods(scope.row.goodsId)">评论</el-button>
            </el-row>
            <el-row>
            <el-button style="width: 100px;margin: 5px"  type="success" icon="el-icon-view" size="mini" @click="viewGoods(scope.row.goodsId)">查看</el-button>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-card style="margin-top: 10px">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="listQuery.page"
        :page-sizes="[3, 5, 10]"
        :page-size="listQuery.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
    </el-card>
    <el-drawer :destroy-on-close="true" @closed="drawerClose" :visible.sync="showDrawer" :show-close="false" size="50%">
      <el-row>
        <el-col style="margin-left: 10px;" :span="4">展示参数</el-col>
        <el-col :span="17">
          <template>
            <el-tag type="success" v-for="attr in showAttrs" :key="attr.id" style="margin-right:10px;margin-bottom: 10px;">{{attr.name}} : {{attr.value}}</el-tag>
          </template>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col style="margin-left: 10px;" :span="4">服务保障</el-col>
        <el-col :span="17">
          <template>
            <el-tag type="success" v-for="term in terms" :key="term.id" style="margin-right:10px;margin-bottom: 10px;">{{term.title}}</el-tag>
          </template>
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col style="margin-left: 10px;" :span="4">轮播图</el-col>
        <el-col :span="17">
          <img style="height: 100px;width: 100px;margin-right: 10px;" :src="item" v-for="item in swiper" :key="item">
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col style="margin-left: 10px;" :span="4">详情图</el-col>
        <el-col :span="17">
          <img style="height: 100px;width: 100px;margin-right: 10px;" :src="item" v-for="item in details" :key="item">
        </el-col>
      </el-row>
      <el-divider></el-divider>
      <el-row>
        <el-col style="margin-left: 10px;" :span="4">规格列表</el-col>
        <el-col :span="17">
          <el-table
            border
            :data="skuList"
            style="width: 100%;margin:20px 0 "
          >
            <el-table-column
              label="序号"
              align="center"
              type="index"
              width="80">
            </el-table-column>
            <el-table-column
              v-for="(item, index) in attrs"
              :key="index"
              :prop="item"
              :label="item"
            >
            </el-table-column>
            <el-table-column
              prop="stock"
              label="库存"
            ></el-table-column>
            <el-table-column
              prop="sales"
              label="销量"
            ></el-table-column>
            <el-table-column
              prop="price"
              label="折扣价"
            ></el-table-column>
            <el-table-column
              prop="oldPrice"
              label="原价"
            ></el-table-column>
          </el-table>
        </el-col>
      </el-row>
    </el-drawer>
    <el-dialog
      title="更改店铺"
      :visible.sync="dialogVisible"
      width="50%"
      @close="dialogClose">
      <el-form :model="shopForm" ref="shopFormRef" label-width="80px">
        <el-form-item label="原店铺" prop="name" v-show="!batchChangeShop">
          <el-input v-model="shopForm.name" :disabled="true"></el-input>
        </el-form-item>
        <el-form-item label="目标店铺" prop="toShopId">
          <el-select
            placeholder="请选择店铺"
            v-model="shopForm.toShopId"
            style="width:100%"
            filterable
            remote
            :remote-method="filterShop"
            :loading="loading"
          >
            <el-option
              :label="item.name"
              :value="item.shopId"
              v-for="item in shopList"
              :key="item.shopId"
            ></el-option>
            <div style="float: right;margin-right:10px;padding-bottom: 10px">
              <el-pagination
                @size-change="shopSizeChange"
                @current-change="shopCurrentChange"
                :current-page="shopQuery.page"
                :page-sizes="[20, 50]"
                :page-size="shopQuery.size"
                layout="total, sizes, prev, pager, next, jumper"
                :total="shopTotal">
              </el-pagination>
            </div>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateShop">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>

import CateSelect from '../../common/CateSelect'
import ShopSelect from '../../common/ShopSelect'
import {
  getGoodsDetail,
  getGoodsList, UpdateGoodsAdStatus,
  UpdateGoodsShop,
  UpdateGoodsStatus,
  UpdateHuntStatus
} from '../../../network/goods'
import { getShop, getShopList } from '../../../network/shop'
import { getSupplierListRequest } from '../../../network/supply'

export default {
  name: 'GoodsList',
  components: {
    CateSelect,
    ShopSelect
  },
  filters: {
    formatLocation (value) {
      switch (value) {
        case 1:
          return '特选'
        case 2:
          return '新品'
        case 3:
          return '热门'
        case 4:
          return '推荐'
        case 5:
          return '折扣'
        case 6:
          return '抢购'
        case 7:
          return '首页'
        case 8:
          return '轮播'
        case 9:
          return '新人专享'
        default:
          return '列表'
      }
    },
    formatFloat (value) {
      return value.toFixed(2)
    }
  },
  created () {
    this.listGoods()
    this.listSupplier()
  },
  data () {
    return {
      listQuery: {
        categoryId: 0,
        shopId: 0,
        name: '',
        page: 1,
        size: 10,
        location: -1,
        supplierId: -1,
        isAdmin: 1,
        sort: ''
      },
      shopQuery: {
        page: 1,
        size: 20,
        name: ''
      },
      locations: [{
        label: '全部',
        value: -1
      }, {
        label: '列表',
        value: 0
      }, {
        label: '特选',
        value: 1
      }, {
        label: '新品',
        value: 2
      }, {
        label: '热门',
        value: 3
      }, {
        label: '推荐',
        value: 4
      }, {
        label: '折扣',
        value: 5
      }, {
        label: '抢购',
        value: 6
      }, {
        label: '首页',
        value: 7
      }, {
        label: '轮播',
        value: 8
      }, {
        label: '新人专享',
        value: 9
      }],
      downloadUrlPrefix: '',
      goodsList: [],
      total: 0,
      isBtnDisabled: true,
      showDrawer: false,
      goodsDetail: {},
      showAttrs: [],
      terms: [],
      details: [],
      swiper: [],
      attrs: [],
      skuList: [],
      dialogVisible: false,
      shopForm: {
        shopId: 0,
        toShopId: '',
        goodsIds: [],
        name: ''
      },
      shopList: [],
      shopTotal: 0,
      loading: false,
      batchChangeShop: false,
      multipleSelection: [],
      suppliers: []
    }
  },
  mounted () {
    this.downloadUrlPrefix = window.g.MEDIA_DOWNLOAD_URL
  },
  methods: {
    changeSort (column) {
      console.log('prop:', column.prop, ',order:', column.order)
      if (!column.order) {
        this.listQuery.sort = ''
        this.listGoods()
        return
      }
      switch (column.prop) {
        case 'rate':
          if (column.order === 'ascending') {
            this.listQuery.sort = 'rate asc'
          } else {
            this.listQuery.sort = 'rate desc'
          }
          break
        case 'salesRate':
          if (column.order === 'ascending') {
            this.listQuery.sort = 'sales_rate asc'
          } else {
            this.listQuery.sort = 'sales_rate desc'
          }
          break
      }
      this.listGoods()
    },
    resetForm () {
      this.$refs.listQuery.resetFields()
      this.listQuery.page = 1
      this.listQuery.size = 10
      console.log('list goods list Query:', this.listQuery)
      this.$refs.categorySelect.categoryId = ''
      this.$refs.shopSelect.shopId = ''
      this.isBtnDisabled = true
    },
    listSupplier () {
      getSupplierListRequest({}).then(res => {
        const result = res.data
        if (res.status !== 200) {
          // return this.alertMessage('获取分享配置列表失败', 'error')
          return
        }
        this.suppliers = []
        this.suppliers.push({
          supplierId: -1,
          name: '全部'
        })
        this.suppliers.push({
          supplierId: 0,
          name: '自建'
        })
        this.suppliers = this.suppliers.concat(result.data.list)
      })
    },
    newListGoods () {
      this.listQuery.page = 1
      this.listGoods()
    },
    listGoods () {
      // this.listQuery.sort = encodeURIComponent('location desc')
      console.log('goods list query:', this.listQuery)
      getGoodsList(this.listQuery).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('商品列表获取失败', 'error')
        }
        this.goodsList = result.data.list
        for (const item of this.goodsList) {
          if (item.icon.includes('http')) {
            item.imageUrl = item.icon
          } else {
            item.imageUrl = this.downloadUrlPrefix + item.icon
          }
          item.rate = parseFloat(item.rate)
          if (item.rate === 0) {
            const calcRate = (item.marketPrice - item.supplyPrice) * 100 / item.supplyPrice
            item.rate = calcRate.toFixed(2)
          } else {
            item.rate = item.rate.toFixed(2)
          }
          if (item.salesRate === 0) {
            const calcSalesRate = (item.price - item.supplyPrice) * 100 / item.supplyPrice
            item.salesRate = calcSalesRate.toFixed(2)
          } else {
            item.salesRate = item.salesRate.toFixed(2)
          }
        }
        console.log('---this.goodsList:', this.goodsList)
        this.total = result.data.total
      })
    },
    editGoods (goods) {
      console.log('edit goods:', goods)
      this.$emit('changeScene', { scene: 1, goodsId: goods.goodsId, categoryId: goods.categoryId, shopId: goods.shopId, operate: 'edit' })
    },
    viewGoods (goodsId) {
      console.log('view goods:', goodsId)
      this.showDrawer = true
      getGoodsDetail(goodsId).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取商品信息失败', 'error')
        }
        this.goodsDetail = res.data.data
        this.showAttrs = this.goodsDetail.ext.showAttrs
        this.terms = this.goodsDetail.ext.terms
        this.details = []
        for (const detail of this.goodsDetail.ext.galleries.detail) {
          // this.details.push(this.downloadUrlPrefix + detail)
          if (detail.includes('http')) {
            this.details.push(detail)
          } else {
            this.details.push(this.downloadUrlPrefix + detail)
          }
        }
        this.swiper = []
        for (const swiper of this.goodsDetail.ext.galleries.swiper) {
          if (swiper.includes('http')) {
            this.swiper.push(swiper)
          } else {
            this.swiper.push(this.downloadUrlPrefix + swiper)
          }
        }
        this.attrs = this.goodsDetail.specs.specs.attrs
        this.skuList = []
        for (const item of this.goodsDetail.specs.skuList) {
          const skuItem = {
            price: item.price,
            oldPrice: item.oldPrice,
            stock: item.stock,
            sales: item.sales
          }
          console.log('---this.attrs:', this.attrs, ';item.attrs:', item.attrs)
          if (this.attrs.length <= 0 || this.attrs[0] === '') {
            this.attrs = []
            for (const attr in item.attrs) {
              this.attrs.push(attr)
            }
          }
          for (const attr of this.attrs) {
            skuItem[attr] = item.attrs[attr]
          }
          this.skuList.push(skuItem)
        }
        console.log('this.skuInfo:', this.goodsDetail)
      })
    },
    handleSizeChange (newSize) {
      this.listQuery.size = newSize
      this.listGoods()
    },

    // 监听页码改变的事件，并发送新的网络请求
    handleCurrentChange (newPage) {
      this.listQuery.page = newPage
      this.listGoods()
    },
    categoryChange (categoryId) {
      this.listQuery.categoryId = categoryId
      if (this.listQuery.shopId !== 0) {
        this.isBtnDisabled = false
      }
      console.log('this.categoryId:', this.listQuery.categoryId)
    },
    shopChange (shopId) {
      this.listQuery.shopId = shopId
      if (this.listQuery.categoryId) {
        this.isBtnDisabled = false
      }
      console.log('this.shopId:', this.listQuery.shopId)
    },
    drawerClose () {
      console.log('drawerClose')
    },
    showDialog (goodsId, shopId) {
      this.batchChangeShop = false
      this.dialogVisible = true
      this.shopForm.goodsIds.push(goodsId)
      this.shopForm.shopId = shopId
      getShop(shopId).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商铺信息失败', 'error')
        }
        this.shopForm.name = result.data.name
        console.log('shop result data:', this.shopForm)
        this.listShop()
      })
    },
    dialogClose () {
      this.$refs.shopFormRef.resetFields()
      console.log('close dialog shopForm:', this.shopForm)
    },
    updateShop () {
      console.log('this.shopForm:', this.shopForm)
      if (!this.shopForm.toShopId) {
        return this.alertMessage('请选择目标分类', 'error')
      }
      UpdateGoodsShop({ goodsIds: this.shopForm.goodsIds, shopId: parseInt(this.shopForm.toShopId) }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('更改店铺失败', 'error')
        }
        this.alertMessage('更改店铺成功', 'success')
        this.dialogVisible = false
        this.listGoods()
      })
    },
    shopSizeChange (newSize) {
      this.shopQuery.size = newSize
      this.listShop()
    },

    // 监听页码改变的事件，并发送新的网络请求
    shopCurrentChange (newPage) {
      this.shopQuery.page = newPage
      this.listShop()
    },
    listShop () {
      getShopList(this.shopQuery).then(res => {
        this.loading = false
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取商铺列表获取失败', 'error')
        }
        this.shopList = result.data.list
        for (const item of this.shopList) {
          if (item.logo.includes('http')) {
            item.imageUrl = item.logo
          } else {
            item.imageUrl = window.g.MEDIA_DOWNLOAD_URL + item.logo
          }
        }
        console.log('this.shoplist:', this.shopList)
        this.shopTotal = result.data.total
      })
    },
    filterShop (query) {
      if (query !== '') {
        this.loading = true
        this.shopQuery.name = query
        this.listShop()
      } else {
        this.loading = true
        this.shopQuery.name = ''
        this.listShop()
      }
    },
    batchUpdateGoodsShop () {
      if (this.multipleSelection.length <= 0) {
        // return this.alertMessage('获选择需要更改的商品', 'error')
        this.$message.warning('请选择需要更改的商品！')
        return
      }
      this.shopForm.goodsIds = []
      for (const item of this.multipleSelection) {
        this.shopForm.goodsIds.push(item.goodsId)
      }
      this.batchChangeShop = true
      this.dialogVisible = true
      this.listShop()
    },
    handleSelectionChange (val) {
      this.multipleSelection = val
      console.log('multipleSelection:', this.multipleSelection)
    },
    changeGoodsStatus (row) {
      UpdateGoodsStatus({ goodsId: row.goodsId, status: row.status }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改商品状态失败', 'error')
        }
        this.alertMessage('修改商品状态成功', 'success')
        this.listGoods()
      })
    },
    changeGoodsLottery (row) {
      UpdateHuntStatus({ goodsId: row.goodsId, isLottery: row.isLottery }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改夺宝状态失败', 'error')
        }
        this.alertMessage('修改夺宝状态成功', 'success')
        this.listGoods()
      })
    },
    changeGoodsAdStatus (row) {
      UpdateGoodsAdStatus({ goodsId: row.goodsId, adStatus: row.adStatus }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('修改推广状态失败', 'error')
        }
        this.alertMessage('修改推广状态成功', 'success')
        this.listGoods()
      })
    },
    commentGoods (goodsId) {
      this.$router.push({
        path: '/goodsSku',
        query: { goodsId: goodsId }
      })
    }
  }
}
</script>

<style scoped>

</style>
