<template>
  <div>
    <el-card :inline="true" shadow="never" style="margin-bottom: 10px">
      <el-row :gutter="5">
        <el-col :span="6">
          <cate-select ref="category" :forbidden="forbiddenCategorySelect"></cate-select>
        </el-col>
        <el-col :span="6">
          <shop-select ref="shop" :forbidden="forbiddenShopSelect" :set-default="shopId"></shop-select>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="submitGoods">确认提交商品</el-button>
        </el-col>
        <el-col :span="3">
          <el-button type="primary" @click="returnGoodsList">返回</el-button>
        </el-col>
      </el-row>
    </el-card>
    <el-card>
      <el-form label-width="100px" size="small" label-position="left">
        <el-tabs v-model="activeName" @tab-click="tabClicked">
          <el-tab-pane label="基本信息" name="base" :disabled="disablePane">
            <goods-base-pane ref="base" :hide-upload=true @locationChange="locationChange"></goods-base-pane>
          </el-tab-pane>
          <el-tab-pane label="规格设置" name="specs" :disabled="disablePane">
            <goods-specs-attr-pane ref="specs" :disable-check=disableCheck></goods-specs-attr-pane>
          </el-tab-pane>
          <el-tab-pane label="展示设置" name="show" :disabled="disablePane">
            <goods-show-attr-pane ref="show" :attrs-list="showAttrs" :term-list="termList"></goods-show-attr-pane>
          </el-tab-pane>
          <el-tab-pane label="图片" name="image" :disabled="disablePane">
            <goods-gallery-pane ref="gallery" :swiper-list="swiperList" :detail-list="detailList"></goods-gallery-pane>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import GoodsBasePane from './GoodsBasePane'
// import CategorySelect from '../../common/CategorySelect'
import ShopSelect from '../../common/ShopSelect'
import GoodsShowAttrPane from './GoodsShowAttrPane'
import GoodsSpecsAttrPane from './GoodsSpecsAttrPane'
import GoodsGalleryPane from './GoodsGalleryPane'
import { getCategory, getGoodsDetail, listAttributeRequest, ListSaleTermRequest, updateGoodsRequest } from '../../../network/goods'
import CateSelect from '../../common/CateSelect'
const NewBuyerGoodsLocation = 9
export default {
  name: 'GoodsEdit',
  components: {
    GoodsSpecsAttrPane,
    GoodsBasePane,
    CateSelect,
    // CategorySelect,
    ShopSelect,
    GoodsShowAttrPane,
    GoodsGalleryPane
  },
  mounted () {
    console.log('this.$route.params.goods', this.$route.query)
    if (this.$route.query.goodsId !== undefined) {
      this.menuTitle = '更新商品'
      this.initGoods(this.$route.query)
    }
  },
  data () {
    return {
      shopId: 0, // 店铺id
      categoryId: 0, // 分类id
      goodsId: 0,
      activeName: 'base', // 当前tab
      disablePane: false, // tabPane是否禁止点击
      goodsDetail: {}, // 商品详情
      showAttrs: [], // 参数属性
      specsAttrs: [], // 规格属性
      termList: [], // 保障条款,
      skuList: [], // 规格商品
      swiperList: [], // 录播图列表
      detailList: [], // 详情图列表
      forbiddenCategorySelect: true,
      forbiddenShopSelect: true,
      disableCheck: false
    }
  },
  methods: {
    // 更新商品时初始化信息
    initGoods (goods) {
      console.log('init goods, goodsId:', goods.goodsId, ', categoryId:', goods.categoryId, ', shopId:', goods.shopId)
      this.goodsId = goods.goodsId
      this.initStatus(goods)
      getGoodsDetail(goods.goodsId).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('获取商品信息失败', 'error')
        }
        this.goodsDetail = res.data.data
        console.log('this.goodsDetail:', this.goodsDetail)
        this.initBase()
        this.initShow()
        this.initTerms()
        this.initGallery()
        this.initSpecs()
        /* this.showAttrs = this.goodsDetail.ext.showAttrs
        this.termList = this.goodsDetail.ext.terms
        this.details = []
        for (const detail of this.goodsDetail.ext.galleries.detail) {
          this.details.push(this.downloadUrlPrefix + detail)
        }
        this.swiper = []
        for (const swiper of this.goodsDetail.ext.galleries.swiper) {
          this.swiper.push(this.downloadUrlPrefix + swiper)
        }
        this.specsAttrs = this.goodsDetail.specs.specs.attrs
        this.skuList = []
        for (const item of this.goodsDetail.specs.skuList) {
          const skuItem = {
            price: item.price,
            oldPrice: item.oldPrice,
            stock: item.stock,
            sales: item.sales
          }
          console.log('---this.attrs:', this.specsAttrs, ';item.attrs:', item.attrs)
          if (this.specsAttrs.length <= 0 || this.specsAttrs[0] === '') {
            this.specsAttrs = []
            for (const attr in item.attrs) {
              this.specsAttrs.push(attr)
            }
          }
          for (const attr of this.specsAttrs) {
            skuItem[attr] = item.attrs[attr]
          }
          this.skuList.push(skuItem)
        } */
      })
    },
    locationChange (val) {
      console.log('edit goods location:', val)
      if (val === NewBuyerGoodsLocation) {
        this.$refs.specs.isNewBuyerGoods = true
      } else {
        this.$refs.specs.isNewBuyerGoods = false
      }
    },
    initStatus (goods) {
      this.$refs.base.disabled = false
      this.categoryId = parseInt(goods.categoryId)
      this.shopId = parseInt(goods.shopId)
      // this.$refs.category.categoryId = this.categoryId
      // this.$refs.shop.shopId = this.shopId
      this.$refs.shop.initShop(this.shopId)
      getCategory(this.categoryId).then(res => {
        const result = res.data
        console.log('self:', result)
        if (res.status !== 200) {
          return this.alertMessage('获取信息失败1', 'error')
        }
        getCategory(result.data.parentId).then(pres => {
          const p = pres.data
          if (pres.status !== 200) {
            // return this.alertMessage('获取信息失败2', 'error')
          }
          if (p) {
            this.$refs.category.setValue(p.data.name + '/' + result.data.name)
          }
        })
      })
    },
    initBase () {
      const base = this.goodsDetail.base
      var minBuy = 1
      if (base.minBuyNum !== 0) {
        minBuy = base.minBuyNum
      }
      var maxBuy = 0
      if (base.maxBuyNum !== 0) {
        maxBuy = base.maxBuyNum
      } else if (base.supplierId !== 0) {
        maxBuy = 5
      }
      var plusStep = 1
      if (base.plusStep !== 0) {
        plusStep = base.plusStep
      }
      if (plusStep !== 1 && base.supplierId !== 0) {
        plusStep = minBuy
      }
      this.$refs.base.baseForm = {
        originSales: base.originSales,
        status: base.status === 1,
        isLottery: base.isLottery === 1,
        isExchange: base.isExchange === 1,
        location: base.location,
        name: base.name,
        desc: base.desc,
        icon: base.icon,
        tag: base.tag,
        coin: base.coin,
        reached: base.reached,
        origin: base.origin,
        minBuy: minBuy,
        maxBuy: maxBuy,
        plusStep: plusStep,
        video: base.video
      }
      if (this.goodsDetail.base.supplierId !== 0) {
        this.disableCheck = true
      } else {
        this.disableCheck = false
      }
      this.$refs.base.fileList = []
      var baseUrl = ''
      if (base.icon.includes('http')) {
        baseUrl = base.icon
      } else {
        baseUrl = window.g.MEDIA_DOWNLOAD_URL + base.icon
      }
      this.$refs.base.fileList.push({
        url: baseUrl,
        mediaId: base.icon,
        remote: true
      })
      if (base.location === NewBuyerGoodsLocation) {
        this.$refs.specs.isNewBuyerGoods = true
      } else {
        this.$refs.specs.isNewBuyerGoods = false
      }
      this.$refs.base.hideUploadPlus()
      if (base.video) {
        if (base.video.includes('http')) {
          this.$refs.base.videoUrl = base.video
        } else {
          this.$refs.base.videoUrl = window.g.MEDIA_DOWNLOAD_URL + base.video
        }
      } else {
        this.$refs.base.videoUrl = ''
      }
    },
    initShow () {
      listAttributeRequest(this.categoryId, 'only').then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取参数失败', 'error')
        }
        this.showAttrs = result.data.list
        for (const attr of this.showAttrs) {
          const result = this.goodsDetail.ext.showAttrs.find(item => {
            return item.name === attr.name
          })
          if (result !== undefined) {
            attr.sv = result.value
          }
        }
        console.log('--------this.showAttrs:', this.showAttrs)
      })
    },
    initTerms () {
      ListSaleTermRequest({}).then(res => {
        const result = res.data
        if (res.status !== 200) {
          return this.alertMessage('获取条款列表获取失败', 'error')
        }
        this.termList = result.data.list
        this.$nextTick(() => {
          for (const [idx, term] of this.termList.entries()) {
            const result = this.goodsDetail.ext.terms.find(item => {
              return item.id === term.id
            })
            if (result !== undefined) {
              this.$refs.show.toggleRowSelection(idx)
            }
          }
        })
      })
    },
    initGallery () {
      this.swiperList = []
      this.detailList = []
      for (const swiper of this.goodsDetail.ext.galleries.swiper) {
        var swiperImageUrl = ''
        if (swiper.includes('http')) {
          swiperImageUrl = swiper
        } else {
          swiperImageUrl = window.g.MEDIA_DOWNLOAD_URL + swiper
        }
        this.swiperList.push(
          {
            url: swiperImageUrl,
            remote: true,
            mediaId: swiper
          }
        )
      }
      for (const detail of this.goodsDetail.ext.galleries.detail) {
        var imageUrl = ''
        if (detail.includes('http')) {
          imageUrl = detail
        } else {
          imageUrl = window.g.MEDIA_DOWNLOAD_URL + detail
        }
        this.detailList.push(
          {
            url: imageUrl,
            remote: true,
            mediaId: detail
          }
        )
      }
    },
    initSpecs () {
      // 选中的属性： 如：['颜色','规格']
      var selectedAttr = this.goodsDetail.specs.specs.attrs
      // 选中的属性值： 如 {"颜色":['红色','黑色']}
      const selectedAttrVal = this.goodsDetail.specs.specs.specs
      console.log('select attr:', selectedAttr, ', val:', selectedAttrVal)
      this.skuList = []
      for (const item of this.goodsDetail.specs.skuList) {
        let caleRate = 0
        if (item.rate === 0) {
          caleRate = (item.marketPrice - item.supplyPrice) * 100 / item.supplyPrice
          caleRate = Math.round(caleRate * Math.pow(10, 2)) / Math.pow(10, 2)
        } else {
          caleRate = item.rate
        }
        let caleSalesRate = 0
        if (item.salesRate === 0) {
          caleSalesRate = (item.price - item.supplyPrice) * 100 / item.supplyPrice
          caleSalesRate = Math.round(caleSalesRate * Math.pow(10, 2)) / Math.pow(10, 2)
        } else {
          caleSalesRate = item.salesRate
        }
        const skuItem = {
          price: item.price,
          oldPrice: item.oldPrice,
          marketPrice: item.marketPrice,
          supplyPrice: item.supplyPrice,
          rate: caleRate,
          salesRate: caleSalesRate,
          stock: item.stock,
          sales: item.sales,
          icon: item.icon,
          newBuyerPrice: item.newBuyerPrice
        }
        if (this.goodsDetail.base.supplierId === 0) {
          skuItem.supplyStatus = 1
        } else {
          skuItem.supplyStatus = item.supplyStatus
        }
        var skuImage = ''
        if (item.icon.includes('http')) {
          skuImage = item.icon
        } else {
          if (item.icon !== '') {
            skuImage = window.g.MEDIA_DOWNLOAD_URL + item.icon
          }
        }
        skuItem.imageUrl = skuImage
        skuItem.fileList = [
          {
            url: skuImage,
            remote: true
          }
        ]
        var key = ''
        for (const attr of selectedAttr) {
          skuItem[attr] = item.attrs[attr]
          key += '_' + item.attrs[attr]
        }
        skuItem.key = key
        this.skuList.push(skuItem)
      }
      console.log('goods suppplier:', this.goodsDetail.base.supplierId)
      console.log('get last skulist from db:', this.skuList)
      this.specsAttrs = []
      // var supplySelectedAttr = this.goodsDetail.specs.specs.attrs
      // const supplySelectedAttrVal = this.goodsDetail.specs.specs.specs
      for (const attr of selectedAttr) {
        const newItem = {
          name: attr,
          // sortNum: idx,
          inputVisible: false,
          inputValue: '',
          attrVals: [],
          editable: []
        }
        console.log('------newItem-----:', newItem)
        // eslint-disable-next-line no-prototype-builtins
        if (selectedAttrVal.hasOwnProperty(attr)) {
          /* newItem.checkedVals = selectedAttrVal[attr]
          const attrVals = []
          for (const val of newItem.checkedVals) {
            const attrVal = {
              val: val,
              checked: false
            }
            attrVals.push(attrVal)
          } */
          newItem.attrVals = selectedAttrVal[attr]
        }
        this.specsAttrs.push(newItem)
      }
      /* if (this.goodsDetail.base.supplierId === 0) {
        const specsAttrs = result.data.list
        for (const attr of specsAttrs) {
          const newItem = {
            name: attr.name,
            id: attr.id,
            sortNum: attr.sortNum,
            checkedVals: []
          }
          // eslint-disable-next-line no-prototype-builtins
          if (selectedAttrVal.hasOwnProperty(attr.name)) {
            newItem.checkedVals = selectedAttrVal[attr.name]
            // console.log('--bbb|||:', newItem, ', checkedvals:', newItem.checkedVals, ', attr.attrVals:', attr.attrVals)
          }
          const attrVals = []
          for (const val of attr.attrVals) {
            const attrVal = {
              val: val,
              checked: false
            }
            attrVals.push(attrVal)
          }
          newItem.attrVals = attrVals
          this.specsAttrs.push(newItem)
        }
      } else {
        var supplySelectedAttr = this.goodsDetail.specs.specs.attrs
        const supplySelectedAttrVal = this.goodsDetail.specs.specs.specs
        for (const [idx, attr] of supplySelectedAttr.entries()) {
          const newItem = {
            name: attr,
            id: idx,
            sortNum: idx,
            checkedVals: []
          }
          // eslint-disable-next-line no-prototype-builtins
          if (supplySelectedAttrVal.hasOwnProperty(attr)) {
            newItem.checkedVals = selectedAttrVal[attr]
            const attrVals = []
            for (const val of newItem.checkedVals) {
              const attrVal = {
                val: val,
                checked: false
              }
              attrVals.push(attrVal)
            }
            newItem.attrVals = attrVals
          }
          this.specsAttrs.push(newItem)
        }
      } */
      this.$nextTick(() => {
        this.$refs.specs.skuList = this.skuList
        this.$refs.specs.tableData = this.specsAttrs
        // this.$refs.specs.changeCheckedSpecs()
        this.$refs.specs.computeSku()
        this.$refs.specs.setIsEdit()
      })
      console.log('specs attr handle:', this.specsAttrs)
    },
    // 点击按钮添加商品
    submitGoods () {
      const base = this.$refs.base.getItem()
      console.log('commit base:', base)
      this.$confirm('是否要提交该商品', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.doCommit()
      }).catch(err => {
        console.log('confirm err:', err)
        this.alertMessage('已取消提交', 'info')
      })
    },
    doCommit () {
      this.$refs.base.validCommit(valid => {
        if (!valid) {
          return this.alertMessage('基本信息不完整', 'error')
        }
        this.commit2Srv()
      })
      /* this.$emit('changeScene', {
        scene: 0,
        operate: 'list'
      }) */
    },
    commit2Srv () {
      const base = this.$refs.base.getItem()
      base.categoryId = this.categoryId
      base.shopId = this.shopId
      const sku = this.$refs.specs.getSku()
      if (!sku.valid) {
        return
      }
      const showData = this.$refs.show.getShowData()
      const gallery = this.$refs.gallery.getGallery()
      if (!gallery.valid) {
        return
      }
      console.log('commit base form:', base)
      console.log('commit show form:', showData)
      console.log('commit specs form:', sku)
      console.log('commit gallery form:', gallery)
      // return
      // eslint-disable-next-line no-unreachable
      updateGoodsRequest(this.goodsId, {
        base: base,
        ext: {
          showAttrs: showData.showAttrs,
          terms: showData.terms,
          galleries: {
            swiper: gallery.swiper,
            detail: gallery.detail
          }
        },
        specs: {
          specs: sku.specs,
          skuList: sku.skuList
        }
      }).then(res => {
        if (res.status !== 200) {
          return this.alertMessage('添加商品失败', 'error')
        }
        console.log('commit goods to srv succ')
        this.$emit('changeScene', {
          scene: 0,
          operate: 'list'
        })
        this.alertMessage('更新商品成功', 'success')
      })
    },
    tabClicked () {
    },
    returnGoodsList () {
      this.$refs.specs.resetBatchVal()
      this.$emit('changeScene', {
        scene: 0,
        operate: 'list'
      })
    }
  }
}
</script>

<style scoped>

</style>
