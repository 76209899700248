<template>
  <div>
    <!-- 顶部面包屑导航 -->
    <breadcrumb-nav>
      <template v-slot:firstMenu>商品管理</template>
      <template v-slot:secondMenu>{{ menuTitle }}</template>
    </breadcrumb-nav>
    <goods-list ref="list" v-show="scene == 0" @changeScene="changeScene"></goods-list>
    <goods-edit ref="edit" v-show="scene == 1" @changeScene="changeScene"></goods-edit>
  </div>
</template>

<script>

import BreadcrumbNav from '../common/BreadcrumbNav'
import GoodsList from './goodsChildComponents/GoodsList'
import GoodsEdit from './goodsChildComponents/GoodsEdit'

export default {
  name: 'Goods',
  components: {
    GoodsEdit,
    GoodsList,
    BreadcrumbNav
  },
  data () {
    return {
      scene: 0, // 0 商品列表   1 修改商品
      menuTitle: '商品列表'
    }
  },
  methods: {
    changeScene (params) {
      this.scene = params.scene
      if (params.operate === 'edit') {
        this.$refs.edit.initGoods(params)
      }
      switch (this.scene) {
        case 0:
          this.menuTitle = '商品列表'
          this.$refs.list.listGoods()
          break
        case 1:
          this.menuTitle = '编辑商品'
          break
        default:
          break
      }
    }
  }
}
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
}

.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
